<template>
  <div class="all content-index">
    <div class="header">
      <div class="header-top">
        <el-page-header @back="back" content="我的贡献分">
        </el-page-header>
      </div>
      <div style="margin-top: 20px">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select v-model="inquire.state" placeholder="状态" clearable>
              <el-option
                  v-for="item in state"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="inquire.designation" width="50%" clearable placeholder="名称">
              <el-option
                  v-for="(item, index) in designationName"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="inquire.grade" placeholder="分数" clearable>
              <el-option
                  v-for="item in num"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" @click="confirm">确定</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="conter-foo">
      <span class="foo-item">共计：{{ total.total }}条记录</span>
      <span class="foo-item">贡献分总得分：{{ total.totalScore }}</span>
      <span class="foo-item">基础分: {{ total.baseScore }}分</span>
      <span class="foo-item">得分：
        <span :style="{color:total.addScore > 0 ? 'red' : (total.addScore < 0 ? 'green' : 'gray')}">
          <span v-if="total.addScore > 0">+</span>{{ total.addScore }}
        </span>
      分</span>
    </div>
    <div class="footer">
      <el-table
          :data="tableData"
          :cell-style="{ textAlign: 'center', color: '#000' }"
          :header-cell-style="{
          textAlign: 'center',
          background: '#EEEEEE',
          color: '#000',
        }"
          style="width: 100%"
      >
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            v-for="item in shoptable"
            :key="item.name"
            v-bind="item"
        >
        </el-table-column>
        <el-table-column prop="score" label="分数">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.score > 0 ? 'red' : (scope.row.score < 0 ? 'green' : 'gray') }">
              <span v-if="scope.row.score > 0">+</span>{{ scope.row.score }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  queryStoreScoreByType,
  queryStoreScoreSummationByType,
} from "@/api/storeManagerPoints/index.js";

export default {
  data() {
    return {
      tableData: [],
      total: [],
      shoptable: [
        {label: "日期", prop: "date"},
        {label: "类别", prop: "scoreType"},
        {label: "状态", prop: "scoreStatus"},
        {label: "渠道", prop: "channelType"},
        {label: "名称", prop: "nameType"},
        {label: "数量", prop: "num"},
        {label: "说明", prop: "illustrate"},
        {label: "有效期", prop: "validity"},
      ],
      state: [
        {value: 0, label: "有效"},
        {value: 1, label: "过期"},
      ],
      num: [
        {value: 1, label: "加分"},
        {value: 2, label: "扣分"},
      ],
      inquire: {
        state: 0, //状态
        designation: "", //名称
        grade: "", //分数
      },
      designationName: [
        {value: 6, label: "工龄贡献"},
        {value: 7, label: "其它贡献"},
      ]

    };
  },
  created() {
    this.queryStoreScoreByType();
    this.queryStoreScoreSummationByType();
  },
  methods: {
    //表格数据
    queryStoreScoreByType() {
      let params = {
        queryFlag: 3,
        status: this.inquire.state,
        scoreType: this.inquire.grade,
        type: this.inquire.designation
      };
      queryStoreScoreByType(params).then((res) => {
        this.tableData = res.data.data;
        console.log(res, "999");
      });
    },
    //数据交互
    queryStoreScoreSummationByType() {
      let params = {
        queryFlag: 3
      }
      queryStoreScoreSummationByType(params).then((res) => {
        if (res.data.code == 0) {
          this.total = res.data.data;
          console.log(res, "交互");
        }
      });
    },
    confirm() {
      this.queryStoreScoreByType();
    },
    back() {
      this.$router.back(-1);
    }
  },
};
</script>

<style scoped>
.all {
  padding: 20px;
}

.conter-foo {
  padding: 15px 0px;
}

.foo-item {
  padding-right: 20px;
}
.footer{
  height: calc(100vh - 250px);
  overflow: auto;
}
</style>
