import request from '@/utils/request';

//店长分页面数据
export function storeUserLookAScore(params) {
    return request({
        url:'/managerPoint/storeScore/storeUserLookAScore',
        method:'get',
        params:params
    })
}
//店长分表格数据
export function getStoreScoreRecordByDate(params) {
    return request({
        url:'/managerPoint/queryRecord/getStoreScoreRecordByDate',
        method:'get',
        params:params
    })
}
//我的服务、行为、贡献
export function queryStoreScoreByType(params) {
    return request({
        url:'/managerPoint/storeScoreChangeDetail/queryStoreScoreByType',
        method:'get',
        params:params
    })
}
//我的交互数据合计
export function queryStoreScoreSummationByType(params) {
    return request({
        url:'/managerPoint/storeScoreChangeDetail/queryStoreScoreSummationByType',
        method:'get',
        params:params
    })
}
//我的交互数据合计
export function getByType(id) {
    return request({
        url:'/managerPoint/copywriting/getByType/' + id,
        method:'get',
    })
}